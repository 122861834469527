<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-width="320px"
              alt="logo"
              contain
              eager
              class="app-logo me-3"
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary text-center mb-2">
            Admin Log In
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <!-- <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <a href="javascript:void(0)" class="mt-1">
                Forgot Password?
              </a>
            </div> -->

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="login"
            >
              Login
            </v-btn>

            <!-- <v-card-text class="d-flex align-center mt-4">
              <v-divider></v-divider>
              <span class="mx-5">or</span>
              <v-divider></v-divider>
            </v-card-text>

            <v-btn
              block
              color="warning"
              href="https://app.grandshipper.com/#/auth/usps"
            >
              Register
            </v-btn> -->
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'

import axios from 'axios'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')

    return {
      isPasswordVisible,
      email,
      password,
    }
  },

  methods: {
    ...mapActions({
      setToken: 'login',
    }),
    async login() {
      try {
        const url = process.env.VUE_APP_API_URL
        const { data } = await axios.post(`${url}/login`, {
          username: this.email,
          password: this.password,
        })
        this.setToken(data.token)
        this.$router.push({ name: 'dashboard' })
      } catch (error) {
        console.error(error)
        this.$root.snackbar.show({message: error.response.data.message, color: 'error'});
      }
    },
  },

  async mounted() {
    document.title = 'Grandshipper Admin';
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
